import type { RecordSourceFieldType, MasterDetailsDefinition } from 'o365.modules.DataObject.Types.ts';

import stringUtils from 'o365.modules.utils.string.js';
import { convertType, translateViewDef } from 'o365.modules.utils.fields.ts';
import type { FieldDataType, FieldType } from 'o365.modules.utils.fields.ts';

export default class DataObjectFields {
    private _viewDefinition: ViewDefinitionFieldType[];
    private _uniqueTableDefinition: ViewDefinitionFieldType[];
    /** Array of selected fields */
    fields: DataObjectField[] = [];
    /** Array of all fields in the view */
    combinedFields: DataObjectField[] = [];
    /**Array of field names that should always be loaded when on demand fields is used */
    loadAlways: string[] = [];
    uniqueField?: string;

    get viewDefinition() { return this._viewDefinition; }
    get uniqueTableDefinition() { return this._uniqueTableDefinition; }

    constructor(pFields: DataObjectDefinitionFieldType[], pViewDefinitionFields?: ViewDefinitionFieldType[], pUniqueTableDefinition?: ViewDefinitionFieldType[]) {
        this._viewDefinition = pViewDefinitionFields ?? [];
        this._uniqueTableDefinition = pUniqueTableDefinition ?? [];

        if (pFields == null) {
            throw new Error('Could not construct DataObjectFields, fields not provided');
        }

        if (this.viewDefinition.length > 0) {
            this.viewDefinition.forEach(viewField => {
                const selectedField = pFields.find(x => x.name === viewField.fieldName);

                let field: DataObjectField;
                if (selectedField) {
                    field = this.createField(selectedField, viewField)
                    this.fields.push(field);
                } else {
                    field = this.createFieldFromViewDefinition(viewField);
                }

                this.combinedFields.push(field);
            });
        } else {
            pFields.forEach(fieldDefinition => {
                const field = this.createField(fieldDefinition);
                this.fields.push(field);
                this.combinedFields.push(field);
            });
        }
        if (pFields.find((field: DataObjectDefinitionFieldType) => field.name == 'PrimKey')) {
            this.uniqueField = 'PrimKey';
        } else if (pFields.find((field: DataObjectDefinitionFieldType) => field.name == 'ID')) {
            this.uniqueField = 'ID';
        }
    }

    /** Create a new field and push it to the selected fields array regardless of if it exists in the view definition */
    addField(pField: DataObjectDefinitionFieldType) {
        if (this.fieldExists(pField.name)) { return; }

        const field = this.createField(pField);
        this.fields.push(field);
        this.combinedFields.push(field);
    }

    /**
     * Create a new field and push it to the selected fields array if such field exists in the view definition,
     * but isn't yet defined for this DataObjectFields instance.
     */
    addFieldIfExists(pField: DataObjectDefinitionFieldType | string) {
        if (typeof pField === 'string') {
            pField = {
                name: pField
            };
        }
        if (this.fieldExists(pField.name)) { return; }
        if (this.fieldExistsInView(pField.name)) {
            this.addField(pField);
        }
    }

    addFieldIfNotExists(field: DataObjectDefinitionFieldType) {
        if (this.fieldExists(field.name)) { return; }

        this.addField(field);
    }

    /**
     * Create Field instance from data object field options and view definition field
     * @param {DataObjectDefinitionFieldType} pField field definition from the data object. These are the options set by developer
     * @param {ViewDefinitionFieldType} pViewDefinitionField field definition from the view definition
     */
    createField(pField: DataObjectDefinitionFieldType, pViewDefinitionField?: ViewDefinitionFieldType) {
        const combinedField: CombinedFieldType = pField;

        const viewFieldDef = pViewDefinitionField ?? this.viewDefinition.find(x => x.fieldName === pField.name);
        const uniqueFieldDef = this.uniqueTableDefinition.find(x => x.fieldName === pField.name);

        if (viewFieldDef) {
            combinedField.dataType = uniqueFieldDef ? uniqueFieldDef.dataType : viewFieldDef.dataType;
            combinedField.computed = uniqueFieldDef ? uniqueFieldDef.computed : viewFieldDef.computed;
            combinedField.identity = uniqueFieldDef ? uniqueFieldDef.identity : viewFieldDef.identity;
            combinedField.hasDefault = uniqueFieldDef ? uniqueFieldDef.hasDefault : viewFieldDef.hasDefault;
            combinedField.nullable = uniqueFieldDef ? uniqueFieldDef.nullable : viewFieldDef.nullable;
            combinedField.maxLength = uniqueFieldDef ? uniqueFieldDef.maxLength : viewFieldDef.maxLength;
            combinedField.joined = uniqueFieldDef ? false : (this.uniqueTableDefinition.length == 0 ? false : true);
            if (viewFieldDef && viewFieldDef.localizedName && viewFieldDef.localizedName != viewFieldDef.fieldName) {
                combinedField.caption = viewFieldDef.localizedName;
            }
        }

        const fieldInstance = new DataObjectField(combinedField);
        (this as any)[fieldInstance.name] = fieldInstance;

        if (viewFieldDef != null || uniqueFieldDef != null) {
            if (!fieldInstance.nullable && !fieldInstance.hasDefault && !fieldInstance.readOnly) {
                fieldInstance.required = true;
            }
        }

        return fieldInstance;
    };

    /**
     * Create field instance from just the view definition field.
     * @param {ViewDefinitionFieldType} pViewDefinitionField field definition from the view definition
     */
    createFieldFromViewDefinition(pViewDefinitionField: ViewDefinitionFieldType) {
        return this.createField({ name: pViewDefinitionField.fieldName, ...pViewDefinitionField }, pViewDefinitionField);
    }

    /** Check if a field exists in the selected fields array */
    fieldExists(pName: string) {
        return this.fields.some(x => x.name === pName);
    }

    /** Check if field exists in the view definition */
    fieldExistsInView(pName: string) {
        return this.viewDefinition.some(x => x.fieldName === pName);
    }

    // Maybe should be included with the viewDefinitions instead?
    // Or maybe a route for retrieving multiple translations at once
    /** Update field translations */
    async translateFields(pViewName: string) {
        const translatedFields = await translateViewDef(pViewName);
        if (translatedFields) {
            Object.keys(translatedFields).forEach((key) => {
                const field = (this as any as DataObjectFieldsType)[key];
                if (field && translatedFields[key]) {
                    field.caption = translatedFields[key]!
                }
            })
        }
    }

    /** @depricated */
    // @ts-ignore
    private filter(pFunction: (value: Field, index: number, array: Field[]) => boolean) {
        console.warn("stop using this, use dataObject.fields.fields.filter instead");
        return this.fields.filter(pFunction);
    }
    /** @depricated */
    // @ts-ignore
    private find(pFunction: (this: void, value: Field, index: number, obj: Field[]) => boolean) {
        console.warn("stop using this, use dataObject.fields.fields.filter find");
        return this.fields.find(pFunction);
    }
    /** @depricated */
    // @ts-ignore
    private findIndex(pFunction: (value: Field, index: number, obj: Field[]) => unknown) {
        console.warn("stop using this, use dataObject.fields.fields.filter findIndex");
        return this.fields.findIndex(pFunction);
    }
    /** @depricated */
    // @ts-ignore
    private sort(pFunction: (a: Field, b: Field) => number) {
        console.warn("stop using this, use dataObject.fields.fields.filter sort");
        return this.fields.sort(pFunction);
    }
    /** @depricated */
    // @ts-ignore
    private forEach(pFnc: (value: Field, index: number, array: Field[]) => void) {
        console.warn("stop using this, use dataObject.fields.fields.filter forEach");
        return this.fields.forEach(pFnc);
    }
    /** @depricated */
    // @ts-ignore
    private push(pArg: any) {
        console.warn("stop using this, use dataObject.fields.fields.filter push");
        this.addField(pArg);
    }

    getFields(): Array<Field>
    getFields(pField: string): Field | undefined
    getFields(pField?: string): Array<Field> | Field | undefined {
        if (pField) {
            return this.fields.find(x => x.name === pField);
        }

        return this.fields;
    }

    getAllFields(): Array<Field>
    getAllFields(pField: string): Field | undefined
    getAllFields(pField?: string): Array<Field> | Field | undefined {
        if (pField) {
            return (this as any)[pField];
        }

        return this.combinedFields;
    }

}

/**
 * DataObject FIeld class for both selected fields and fields from a view
 */
export class DataObjectField<T = any> {
    private _caption: string;
    /** Column name of the field */
    name: string;
    /** Framework friendly field type of this field */
    type: FieldType;
    /** SQL type of this field */
    dataType: FieldDataType | null;
    sortOrder: number | null;
    sortDirection: 'asc' | 'desc' | null;
    groupByOrder: number | null = null;
    groupByAggregate: 'COUNT' | 'SUM' | 'MIN' | 'MAX' | 'AVG' | null = null;
    aggregate: 'COUNT' | 'SUM' | 'MIN' | 'MAX' | 'AVG' | null = null;
    /**
     * When selecting this field will wrap it with ROUND(name, value)
     * 0 will round to integer, 1 will round to decimal 1, 01 will round to nearest 10, etc.
     */
    round?: number;

    /** Field is computed */
    computed: boolean;
    /** Field is identity type */
    identity: boolean;
    /** Field is joined in view */
    joined: boolean;
    /** Field has default value in SQL */
    hasDefault: boolean;
    /** Field is nullable */
    nullable: boolean;
    /** The max value length for this field */
    maxLength: number;
    /** Optional default value used when creating new items in storage */
    defaultValue?: T;
    /** Optional default value function that is called when creating new items in storage */
    defaultValueFunction?: () => T;
    /** Field is read only */
    readOnly: boolean;
    /**
     * Indicates that the field should be hidden in places where all of the view definition fields are listed. 
     * For example the list in FieldFiltersChooser
     */
    hideFromList = false;
    /**
     * When set this field will be treated as a stringified json field. 
     * The sub-properties of can be accessed through `dataItem[jsonAlias]`.
     */
    jsonAlias: string | null = null;
    /** Array of field names that should be loaded alongside this field when on demand fields is used */
    dependantFields: string[] = [];
    /**
     * Options used for filtering with exists clauses. Used by properties.
     */
    existsDefinition?: DataObjectDefinitionFieldType['existsDefinition'];
    /**
     * Custom caption, optional, used to override the default translated caption
     */
    customCaption: string | null = null;
    /**
     * Mark this field as requried. If the field comes from view definition then this
     * value is set by default depending on if the field is not nullable, has no default value and is not readonly
     */
    required?: boolean;

    /** Getter for various controls that use field instead of name property */
    get field() { return this.name; }

    get caption() {
        return this.customCaption ?? this._caption ?? this.name;
    }

    set caption(pCaption: string) {
        this._caption = pCaption;
    }

    /** The options from this field to be used in DataHandler operations */
    get item(): RecordSourceFieldType {
        return {
            name: this.name,
            sortOrder: this.sortOrder ? this.sortOrder : undefined,
            sortDirection: this.sortDirection ? this.sortDirection : undefined,
            groupByOrder: this.groupByOrder ? this.groupByOrder : undefined,
            groupByAggregate: this.groupByAggregate ? this.groupByAggregate : undefined,
            aggregate: this.aggregate ? this.aggregate : undefined,
            round: this.round != null ? this.round : undefined
        }
    }

    constructor(pField: CombinedFieldType) {
        this.name = pField.name;
        this.type = pField.type ?? (pField.dataType ? convertType(pField.dataType) : 'string');
        this.dataType = pField.dataType ?? null;
        this.sortOrder = pField.sortOrder ?? null;
        this.sortDirection = pField.sortDirection ?? null;

        if (pField.groupByOrder != null) {
            this.groupByOrder = pField.groupByOrder;
            this.groupByAggregate = pField.groupByAggregate ?? null;
        } else {
            this.aggregate = pField.groupByAggregate ?? null;
        }

        // TODO(Augustas): Neither view definition or data object definition have captions defined, could be maybe missing in backend
        this._caption = pField.caption ? pField.caption : stringUtils.unCamelCaseAndTranslate(this.name);
        this.computed = pField.computed ?? false;
        this.identity = pField.identity ?? false;
        this.joined = pField.joined ?? false;
        this.hasDefault = pField.hasDefault ?? false;
        this.nullable = pField.nullable ?? false;
        this.maxLength = pField.maxLength ?? 250;
        this.readOnly = this.computed || this.identity || this.joined;

        if (pField.existsDefinition) {
            this.existsDefinition = pField.existsDefinition;
        }

        if (pField.defaultValue) {
            this.defaultValue = pField.defaultValue;
        }
        if (pField.defaultValueFunction) {
            this.defaultValueFunction = pField.defaultValueFunction;
        }
    }


}

/** Combined data object and view definition fields used when creating new DataObjectField instances */
export type CombinedFieldType = DataObjectDefinitionFieldType & Partial<ViewDefinitionFieldType> & {
    joined?: boolean;
    caption?: string;
};

/**
 * Field definition from a DataObject. 
 * These options are set in the appdesigner or passed directly to the data object create functions
 */
export type DataObjectDefinitionFieldType<T = any> = {
    name: string;
    sortOrder?: number | null;
    sortDirection?: 'asc' | 'desc' | null;
    groupByOrder?: number | null;
    groupByAggregate?: 'COUNT' | 'SUM' | 'MIN' | 'MAX' | 'AVG' | null;
    aggregate?: 'COUNT' | 'SUM' | 'MIN' | 'MAX' | 'AVG' | null;
    type?: FieldType;
    dataType?: FieldDataType;
    alias?: string;
    /** Not available in appdesigner */
    defaultValue?: T;
    /** Not available in appdesigner */
    defaultValueFunction?: () => T;
    /** Not available in appdesigner */
    existsDefinition?: {
        viewName: string,
        valueField?: string,
        binding: MasterDetailsDefinition[],
    }
};

/**
 * Field definition from a view. 
 * These definitions are retrieved and stored in o365.modules.configs.ts
 */
export type ViewDefinitionFieldType = {
    fieldName: string;
    maxLength: number;
    dataType: FieldDataType;
    computed: boolean;
    identity: boolean;
    hasDefault: boolean;
    nullable: boolean;
    localizedName: string;
};

/** Framework friendly type used by controls */
export { FieldType, FieldDataType };
/** SQL type of a field */
//export type FieldDataType;
export type DataObjectFieldsType = Record<string, DataObjectField | undefined> & DataObjectFields;

//--- Compatibility exports ---
export type Field = DataObjectField;
export type Fields = DataObjectFields;